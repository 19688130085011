import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import ArticleList from "../../components/ArticleList";
import FeaturedArticle from "../../components/FeaturedArticle";

function ArticleListingPage({
	pageContext,
	data: { articles },
}) {
	const { title, seoDescription } = pageContext;

	return (
		<Layout>
			<SearchEngineOptimization
				title={`${title}`}
				description={
					seoDescription
						? seoDescription.seoDescription
						: `Irish Titan ${title} articles.`
				}
			/>
			<FeaturedArticle />
			<section className="section">
				<div className="container">
					<ArticleList articles={articles.edges} />
				</div>
			</section>
		</Layout>
	);
}

export const query = graphql`
	query($title: String) {
		articles: allContentfulArticle(
			filter: { category: { title: { eq: $title } } }
			sort: { fields: publishedDate, order: DESC }
		) {
			edges {
				node {
					title
					slug
					previewImage {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							aspectRatio: 1.64
						)
						description
						title
					}
					heroImage {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							aspectRatio: 1.64
						)
						description
						title
					}
					category {
						title
					}
					excerpt {
						excerpt
					}
					tags
				}
			}
		}
	}
`;

export default ArticleListingPage;